<template>
    <v-container fluid>
        <v-row class="mt-n5 mb-2">
            <v-col cols="4">
                <h2 class="black--text">INDICATORS</h2>
            </v-col>
            <v-spacer />
            <v-col cols="2" class="d-flex justify-end">
                <v-btn color="primary" @click="openReport = true">
                    Report
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" xl="6" lg="6" md="6">
                <h2 class="mb-3 black--text">
                    Level of attention to purchases (Items)
                </h2>
                <v-data-table
                    :items="levelAttentionToPurchases"
                    :headers="headers"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :sort-by="['month']"
                    :sort-desc="[false]"
                >
                    <!--HEADERS-->
                    <template v-slot:[`header.new`]="{ header }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <h3
                                    class="white--text"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ header.text }}
                                </h3>
                            </template>
                            <div>
                                <span>Pricing</span><br />
                                <span>preApproval</span><br />
                                <span>Approval</span><br />
                                <span>Purchasing</span>
                            </div>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.accum`]="{ header }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <h3
                                    class="white--text"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ header.text }}
                                </h3>
                            </template>
                            <div><span>Previous Month Pending</span><br /></div>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.processed`]="{ header }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <h3
                                    class="white--text"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ header.text }}
                                </h3>
                            </template>
                            <div>
                                <span>Intransit</span><br />
                                <span>Received</span><br />
                                <span>Closed</span><br />
                            </div>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.rejected`]="{ header }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <h3
                                    class="white--text"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ header.text }}
                                </h3>
                            </template>
                            <div><span>Rejected This Month</span><br /></div>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.pending`]="{ header }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <h3
                                    class="white--text"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ header.text }}
                                </h3>
                            </template>
                            <div>
                                <span>Pricing</span><br />
                                <span>PreApproval</span><br />
                                <span>Approval</span><br />
                                <span>Purchasing</span><br />
                                <span>Rejected</span><br />
                            </div>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.performance`]="{ header }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <h3
                                    class="white--text"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ header.text }}
                                </h3>
                            </template>
                            <div>
                                <span
                                    >Processed / (New + Accum - Rejected)</span
                                >
                            </div>
                        </v-tooltip>
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.month`]="{ item }">
                        <p class="my-0 text-capitalize">
                            {{ formatMonth(item.month) }}
                        </p>
                    </template>
                    <template v-slot:[`item.performance`]="{ item }">
                        <td
                            :style="
                                `background-color: ${getColorPerformance(
                                    item.performance
                                )} !important`
                            "
                        >
                            <p class="white--text text-center my-0">
                                {{ item.performance.toFixed(2) }}
                            </p>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6">
                <Highcharts :options="levelAttentionChart" />
            </v-col>
        </v-row>
        <v-row no-gutters class="my-2">
            <v-col cols="12" xl="6" lg="6" md="6">
                <h2 class="mb-3 black--text">
                    Closed Orders (Items)
                </h2>
                <v-data-table
                    :items="closedOrders"
                    :headers="headersToClosedOrders"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :sort-by="['month']"
                    :sort-desc="[false]"
                >
                    <template v-slot:[`item.month`]="{ item }">
                        <p class="my-0 text-capitalize">
                            {{ formatMonth(item.month) }}
                        </p>
                    </template>
                    <template v-slot:[`item.performance`]="{ item }">
                        <td
                            :style="
                                `background-color: ${getColorPerformance(
                                    item.performance
                                )} !important`
                            "
                        >
                            <p class="white--text text-center my-0">
                                {{ item.performance.toFixed(2) }}
                            </p>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6">
                <Highcharts :options="closedOrdersChart" />
            </v-col>
        </v-row>
        <v-row no-gutters class="my-2">
            <v-col cols="12" xl="6" lg="6" md="6">
                <h2 class="mb-3 black--text">
                    Open Orders (Items)
                </h2>
                <v-data-table
                    :items="openOrders"
                    :headers="headersToOpenOrders"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :sort-by="['month']"
                    :sort-desc="[false]"
                >
                    <template v-slot:[`item.month`]="{ item }">
                        <p class="my-0 text-capitalize">
                            {{ formatMonth(item.month) }}
                        </p>
                    </template>
                    <template v-slot:[`item.performance`]="{ item }">
                        <td
                            :style="
                                `background-color: ${getColorPerformance(
                                    item.performance
                                )} !important`
                            "
                        >
                            <p class="white--text text-center my-0">
                                {{ item.performance.toFixed(2) }}
                            </p>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6">
                <Highcharts :options="openOrdersChart" />
            </v-col>
        </v-row>
        <!--Report Dialog-->
        <v-dialog
            :retain-focus="false"
            persistent
            width="400"
            v-model="openReport"
        >
            <Report v-if="openReport" @close="closeReport" />
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { Chart } from 'highcharts-vue'
import Report from '@/components/Purchasing/Report.vue'
import moment from 'moment'

export default {
    name: 'Indicators',
    components: {
        Highcharts: Chart,
        Report,
    },
    data: () => ({
        openReport: false,
        loading: false,
        // starts from the current month and ends with the month six months ago.
        monthDates: [],
        purchases: [],
        // starts from the current month and ends with the month six months ago.
        months: [],
        // starts from the current month and ends with the month six months ago.
        pendingByMonth: [],
        levelAttentionToPurchases: [],
        closedOrders: [],
        openOrdersChart: {},
        closedOrdersChart: {},
        levelAttentionChart: {},
        openOrders: [],
        headers: [
            {
                text: 'Month',
                value: 'month',
                sortable: false,
                align: 'center',
            },
            {
                text: 'New',
                value: 'new',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Accum',
                value: 'accum',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Processed',
                value: 'processed',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Rejected',
                value: 'rejected',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Pending',
                value: 'pending',
                sortable: false,
                align: 'center',
            },
            {
                text: '%',
                value: 'performance',
                sortable: false,
                align: 'center',
            },
        ],
        headersToClosedOrders: [
            {
                text: 'Month',
                value: 'month',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Received',
                value: 'received',
                sortable: false,
                align: 'center',
            },
            {
                text: 'OnTime',
                value: 'onTime',
                sortable: false,
                align: 'center',
            },
            {
                text: 'delayed',
                value: 'delayed',
                sortable: false,
                align: 'center',
            },
            {
                text: '% onTime',
                value: 'performance',
                sortable: false,
                align: 'center',
            },
        ],
        headersToOpenOrders: [
            {
                text: 'Month',
                value: 'month',
                sortable: false,
                align: 'center',
            },
            {
                text: 'To Receive',
                value: 'toReceive',
                sortable: false,
                align: 'center',
            },
            {
                text: 'On Time',
                value: 'onTime',
                sortable: false,
                align: 'center',
            },
            {
                text: 'delayed',
                value: 'delayed',
                sortable: false,
                align: 'center',
            },
            {
                text: '% onTime',
                value: 'performance',
                sortable: false,
                align: 'center',
            },
        ],
        monthsToShow: [],
    }),
    created() {
        this.levelAttentionChart = {
            title: {
                text: '',
            },
            xAxis: {
                type: 'category',
                title: {
                    text: '',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            legend: {
                enabled: false,
            },
            series: [],
            credits: {
                enabled: false,
            },
            accessibility: { enabled: false },
        }
        this.closedOrdersChart = {
            title: {
                text: '',
            },
            xAxis: {
                type: 'category',
                title: {
                    text: '',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            legend: {
                enabled: false,
            },
            series: [],
            credits: {
                enabled: false,
            },
            accessibility: { enabled: false },
        }
        this.openOrdersChart = {
            title: {
                text: ' ',
            },
            xAxis: {
                type: 'category',
                title: {
                    text: '',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            legend: {
                enabled: false,
            },
            series: [],
            credits: {
                enabled: false,
            },
            accessibility: { enabled: false },
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.getDates()
            const inform = await API.purchaseIndicators({
                start: this.monthDates[5].start,
                end: this.monthDates[0].end,
            })

            this.structureData(inform)

            // print data
            this.printLevelAttentionChart()
            this.printClosedOrdersChart()
            this.printOpenOrdersChart()

            this.loading = false
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        structureData(inform) {
            Object.keys(inform).forEach(key => {
                const levelOfAttentionRow = {
                    month: key,
                    new: inform[key].new,
                    accum: inform[key].accum,
                    processed: inform[key].processed,
                    rejected: inform[key].rejected,
                    pending: inform[key].pending,
                }
                const closedRow = {
                    month: key,
                    received: inform[key].receivedClosed,
                    onTime: inform[key].onTimeClosed,
                    delayed: inform[key].delayedClosed,
                    toReceive: inform[key].toReceiveClosed,
                }
                const openRow = {
                    month: key,
                    received: inform[key].receivedOpen,
                    onTime: inform[key].onTimeOpen,
                    delayed: inform[key].toReceiveOpen - inform[key].onTimeOpen,
                    toReceive: inform[key].toReceiveOpen,
                }
                this.levelAttentionToPurchases.push(levelOfAttentionRow)
                this.closedOrders.push(closedRow)
                this.openOrders.push(openRow)
            })
            for (const index in this.levelAttentionToPurchases) {
                const row = this.levelAttentionToPurchases[index]
                this.levelAttentionToPurchases[index].performance =
                    (row.processed * 100) / (row.new + row.accum)
            }

            this.levelAttentionToPurchases = this.levelAttentionToPurchases.filter(
                row => this.monthsToShow.includes(row.month)
            )

            this.levelAttentionToPurchases.sort(
                (a, b) => moment(a.month) - moment(b.month)
            )

            this.closedOrders.sort((a, b) => moment(a.month) - moment(b.month))
            for (const index in this.closedOrders) {
                const row = this.closedOrders[index]

                this.closedOrders[index].performance =
                    (row.onTime * 100) / (row.onTime + row.delayed)
            }

            this.closedOrders = this.closedOrders.filter(row =>
                this.monthsToShow.includes(row.month)
            )

            this.openOrders.sort((a, b) => moment(a.month) - moment(b.month))
            for (const index in this.openOrders) {
                const row = this.openOrders[index]

                this.openOrders[index].performance =
                    (row.onTime * 100) / (row.onTime + row.delayed)
            }

            this.openOrders = this.openOrders.filter(row =>
                this.monthsToShow.includes(row.month)
            )
        },
        formatMonth(month) {
            return moment(month, 'YYYY-MM').format('MMM')
        },
        closeReport() {
            this.openReport = false
        },
        printOpenOrdersChart() {
            this.openOrdersChart.xAxis.categories = this.openOrders.map(
                item => item.month
            )
            this.openOrdersChart.series = [
                {
                    name: 'performance',
                    data: this.openOrders.map(item => ({
                        name: item.month,
                        y: parseFloat(item.performance.toFixed(2)),
                    })),
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                    },
                },
            ]
        },
        printClosedOrdersChart() {
            this.closedOrdersChart.xAxis.categories = this.closedOrders.map(
                item => item.month
            )
            this.closedOrdersChart.series = [
                {
                    name: 'performance',
                    data: this.closedOrders.map(item => ({
                        name: item.month,
                        y: parseFloat(item.performance.toFixed(2)),
                    })),
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                    },
                },
            ]
        },
        getColorPerformance(performance) {
            if (performance >= 80) {
                return 'green'
            } else if (performance >= 70 && performance < 80) {
                return '#F1C40F'
            } else if (performance > 0 && performance < 70) {
                return 'red'
            } else if (performance == 0) {
                return '#ccc'
            }
            return '#ccc'
        },
        printLevelAttentionChart() {
            this.levelAttentionChart.xAxis.categories = this.levelAttentionToPurchases.map(
                item => item.month
            )
            this.levelAttentionChart.series = [
                {
                    name: 'performance',
                    data: this.levelAttentionToPurchases.map(item => ({
                        name: item.month,
                        y: parseFloat(item.performance.toFixed(2)),
                    })),
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                    },
                },
            ]
        },
        getDates() {
            // Get the current date
            let currentDate = new Date()

            // Loop for the last 6 months
            for (let i = 0; i < 6; i++) {
                // Get the first day of the month
                const firstDay = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() - i,
                    1
                )

                // Get the last day of the month
                const lastDay = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() - i + 1,
                    0
                )

                // Format the dates as strings in the desired format (e.g., "YYYY-MM-DD")
                const firstDayStr = firstDay.toISOString().slice(0, 10)
                const lastDayStr = lastDay.toISOString().slice(0, 10)

                // Add the dates to the array
                this.monthDates.push({ start: firstDayStr, end: lastDayStr })
                this.monthsToShow.push(firstDayStr.slice(0, 7))
            }
        },
    },
}
</script>

<style scoped>
::v-deep(.v-data-table-header th) {
    background-color: #1976d2 !important;
}
::v-deep(span) {
  color: #ffffff;
}
.v-data-table {
    border: 1px solid #eeeeee;
}
</style>
